#sow {
  background-color: #161616;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: all 0.4s;
  border: 2px solid black;
  padding: 15px 25px;
  font-size: 16px;
}

#sow:hover {
  background-color: transparent;
  border: 2px solid #333232;
  color: rgb(255, 255, 255);
  padding: 15px 30px;
}
