.footer-text p {
  color: rgb(184, 178, 178);
}

.text-end a {
  color: rgb(184, 178, 178);
  text-decoration: none;
  margin: 0px 10px;
}

.text-end a:hover {
  color: rgb(201, 198, 198);
}
