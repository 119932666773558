/* .card1{
    transform: scale(1);
transition: transform .7s;
border-radius: 5px;
border: none;
width: 300px;
background-color: #e67e22;
}
.card1:hover{
    transform: scale(1.10);
    border-radius: 5px;
    }
    .card1-ed{
        background-color: white;
        filter: blur(4);
        font-size: 20px;
        font-weight: bold;
             padding: 30px;
        transition: 1s;
        border: 1px solid rgb(13, 160, 26);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
    
    
    .card1-ed:hover{
        transform: scale(1.10);
    
      padding: 25px;
    
    
    }

.card2{
    transform: scale(1);
transition: transform .7s; 
border-radius: 5px;
border: none;
width: 300px;
border: 2px solid #e67411;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.card2:hover{
    transform: scale(1.10);
    border-radius: 5px;
    }

.card-ed{
    font-size: 20px;
    font-weight: bold;

    padding: 30px;
    transition: 1s;
    border: 1px solid rgb(13, 160, 26);
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


.card-ed:hover{
    transform: scale(1.10);
 
  padding: 25px;


}

.card-item{
    transform: scale(1);
transition: transform .7s;

}
.card-item:hover{
    transform: scale(1.10);
    } */

/* 
    .run2{
      display: flex;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    } */
.back {
  /* background-image: url("/public/images/05e3dc_9a652459a41748d1bba89f5918d38055.jpg"); */
  border-radius: 10px;
}

.run2 {
  display: flex;
  max-height: 100vh;
}

.collect-container2 {
  background-color: rgba(128, 128, 128, 0.116);
  width: 100%;
  /* margin: auto; */
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 20px;
  border-radius: 10px;
  /* height: 378px !important; */
}

.head2 {
  color: rgb(206, 202, 202);
  /* text-decoration: underline; */
  z-index: 1;
}

.run1 {
  display: flex;
  /* margin-top: 10px; */
}

.collect-container1 {
  background-color: rgba(128, 128, 128, 0.208);

  /* margin: auto; */
  padding: 20px;
  border-radius: 10px;
}

.head1 {
  color: #cecaca;
  padding: 10px;
  /* text-decoration: underline; */
  border-radius: 30px;
}

.collect {
  /* padding: 20px; */
  border-radius: 10px;
  background-color: #f2f2f200;
}

.success-message {
  font-family: "Roboto", sans-serif;
  background-color: #3f89f8;
  padding: 15px;
  color: rgb(0, 0, 0);
}

.form-field1 {
  margin: 10px 0 10px 0;
  padding: 15px;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
}

.input {
  background: #f2f2f200;
  padding: 10px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  transform: scale(1);
  transition: transform 0.7s;
  width: 100%;
}

.input:hover {
  transform: scale(1);
}

.input span {
  float: right;
}

hr {
  height: 3px;
  color: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
}

select {
  background: #f2f2f2;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

.buttonstake {
  background-color: #161616;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: all 0.4s;
  border: 2px solid black;
  padding: 15px 25px;
  font-size: 16px;
  /* border: none; */
}
.buttonstake:hover {
  background-color: transparent;
  border: 2px solid #333232;
  color: rgb(255, 255, 255);
}
.banner_im {
  /* border: 2px solid rgba(176, 175, 175, 0.535); */
  border-radius: 15px;
  filter: brightness(150%);
  height: 65vh;
  margin-top: 11px;
}

/* .buttonstake{
  background-color: #80808096;
  padding: 15px 50px 15px 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 7px;
border: none;
transition: 0.5s;
color: white;
}

.buttonstake:hover{
  background-color: rgba(128, 128, 128, 0.116);
  padding:15px 35px 15px 35px;
  color: white;
 } */
.im_back {
  /* height: 100vh; */
  /* padding: 60px 0px; */
}

.im_back video {
  /* margin: 0px;
  padding: 0px;
  object-fit: cover;
  border-radius: 10px;
  height: 378px !important; */
  /* border: 1px solid red; */
}
.mintCard {
  padding: 100px 0px;
}
video {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vh;
  object-fit: cover;
  /* z-index: 1; */
  opacity: 0.3;
  /* padding: 0rem 1.5rem !important; */
}

.imgo {
  width: 30px !important;
  filter: invert(1);
}

@media (max-width: 601px) {
  .im_back {
    /* height: 100vh; */
    padding: 30px 0px;
  }
  .gkdjfkd {
    text-align: center;
    border-radius: 10px;
  }
  .run1 {
    padding: 10px;
  }
  .run2 {
    /* display: flex;
    max-height: 100vh;
    padding: 10px; */
  }
  .row > * {
    /* padding: 0px !important; */
  }
}
