.header {
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}
a {
  border-radius: 10px !important;
}

#navbarNav ul li a {
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  /* font-weight: bold; */
  font-weight: 400;
}

#navbarNav ul li a:hover {
  color: rgb(226, 225, 226);
}

.button14 {
  background-color: #161616;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: all 0.4s;
  border: 2px solid black;
  padding: 15px 25px;
  font-size: 16px;
  /* border: none; */
}
.button14:hover {
  background-color: transparent;
  border: 2px solid #333232;
  color: rgb(255, 255, 255);
}
.container-fluid {
  /* padding: 0px !important; */
}
.navbar {
  height: 90px;
}
.bgBlack {
  background: black !important;
}
.video_main {
  margin: 0px;
  padding: 0px;
}
iframe {
  display: none;
}
