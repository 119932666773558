/* input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
} */
/* input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}

.form-control + .form-control {
  margin-top: 1em;
} */
/* input[type="checkbox"] {
  /* ...existing styles */

/* display: grid;
  place-content: center;
}  */
/* input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
} */

/* input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]::before {
  /* ...existing styles */

/* Windows High Contrast Mode */
/* background-color: rgb(0, 0, 0);
} */
input[type="checkbox"] {
  accent-color: black;
  /* width: 30px; */
}

.button12 {
  background-color: #161616;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: all 0.4s;
  padding: 15px 25px;
  font-size: 16px;
  border: 2px solid black;
}

.button12:hover {
  background-color: transparent;
  border: 2px solid #333232;
  color: rgb(255, 255, 255);
  padding: 15px 30px;
}
.inpocoma {
  padding: 0px 10px;
  border-radius: 5px;
  background-color: rgb(224, 224, 224);
  color: rgb(74, 74, 74);
}
.inpo {
  border-radius: 5px;
  background: transparent;
  border: none;
  top: 3px !important;
  border: 1px solid rgb(192, 192, 192);
  color: white;
  padding: 0px 10px;
  left: 8px !important;
}

.nft {
  color: transparent;
  margin-top: 40px;
}

.staking-main .card {
  background-color: #212529;
  position: relative;
}

.card input {
  position: absolute;
  right: 13px;
  top: 13px;
}

.nt_found h4 {
  color: rgba(255, 255, 255, 0.695);
  /* width: 400px; */
}
