.contact-main {
  /* border: 2px solid green; */
  padding: 30px 50px 30px 50px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transform: scale(1);
  transition: transform 0.5s;
  letter-spacing: 1px;
  color: rgb(88, 94, 92);
}
/*  */
.lowerBanner {
  background-color: rgb(0, 0, 0);
  opacity: 1;
  z-index: 999;
  position: relative;
}
.contact-main:hover {
  transform: scale(1.03);
}

.set {
  transform: scale(1);
  transition: transform 0.5s;
}

.set:hover {
  transform: scale(1.1);
}

.head2 span {
  font-weight: bold;
  font-size: 20px;
}
